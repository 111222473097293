<template>
  <div class="globalAvatar">
    <img :src="user.picture" :alt="user.user" v-if="user.picture" v-show="isLoadingLocal" @load="isLoadingLocal = true" />
    <span v-if="isShow" fcenter>{{ avatar }}</span>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoadingLocal: false,
    };
  },
  computed: {
    isShow() {
      if (!this.user.picture) return true;
      if (!this.isLoadingLocal) return true;
      return false;
    },
    avatar() {
      if (this.user && this.user.role && (this.user.artisticName || this.user.user))
        return this.user?.role === "model" ? this.user?.artisticName?.substring(0, 1) : this.user?.user?.substring(0, 1);
      return "";
    },
  },
};
</script>
<style lang="scss">
.globalAvatar {
  border-radius: 50%;
  overflow: hidden;
  background-color: $primary_color;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: fadeIn 0.5s ease-in-out;
  }
  span {
    width: 100%;
    height: 100%;
    color: white;
    text-transform: uppercase;
  }
}
</style>
